import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "create-instrctor" }
const _hoisted_2 = { class: "create-instrctor__title" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "create-instrctor__images-container" }
const _hoisted_6 = { class: "create-instrctor__images-preview-wrap" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]
const _hoisted_10 = { key: 4 }
const _hoisted_11 = { class: "create-instrctor__photo-block" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = {
  key: 1,
  class: "create-instrctor__code-copy-wrap"
}
const _hoisted_16 = { class: "create-instrctor__code-copy" }
const _hoisted_17 = {
  key: 2,
  class: "create-instrctor__copied"
}
const _hoisted_18 = { class: "form-group create-instrctor__select-title" }
const _hoisted_19 = { class: "form-group" }
const _hoisted_20 = { class: "create-instrctor__button" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowBack = _resolveComponent("ArrowBack")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PhotoMock = _resolveComponent("PhotoMock")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_Field = _resolveComponent("Field")!
  const _component_Select = _resolveComponent("Select")!
  const _component_CopyInstructor = _resolveComponent("CopyInstructor")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/super-admin/school-list",
      class: "create-instrctor__back"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ArrowBack)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (!this.editTeg)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, " Create school "))
        : _createCommentVNode("", true),
      (this.editTeg)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, " Edit school "))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Form, {
      onSubmit: _ctx.createInstructor,
      class: "create-instrctor__form"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (!this.isPreview && this.schoolData.image === undefined)
              ? (_openBlock(), _createBlock(_component_PhotoMock, { key: 0 }))
              : _createCommentVNode("", true),
            (this.isPreview && this.schoolData.image === null)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: _ctx.imagePreview,
                  class: "create-instrctor__images-preview"
                }, null, 8, _hoisted_7))
              : _createCommentVNode("", true),
            (this.isPreview && this.schoolData.image !== null)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 2,
                  src: _ctx.imagePreview,
                  class: "create-instrctor__images-preview"
                }, null, 8, _hoisted_8))
              : _createCommentVNode("", true),
            (this.schoolData.image !== null && !this.isPreview && this.schoolData.image !== undefined)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 3,
                  src: this.schoolData.image,
                  class: "create-instrctor__images-preview"
                }, null, 8, _hoisted_9))
              : _createCommentVNode("", true),
            (this.errorInstructor)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_AlertBlock, {
                    message: this.errorInstructor.image
                  }, null, 8, ["message"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_11, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "create-instrctor__upload-photo first-button" }, "Upload ", -1)),
              _createElementVNode("input", {
                type: "file",
                id: "file",
                ref: "file",
                accept: ".png, .jpg, .jpeg",
                onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleFileUpload()))
              }, null, 544),
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "create-instrctor__upload-info" }, " Picture must be 300 px * 300 px and no more than 10 MB ", -1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _cache[6] || (_cache[6] = _createElementVNode("label", {
            for: "name",
            class: "form-group-label"
          }, "School name ", -1)),
          _createVNode(_component_Field, {
            class: "form-control",
            type: "text",
            name: "name",
            modelValue: this.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.name) = $event))
          }, null, 8, ["modelValue"]),
          (this.errorInstructor)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createVNode(_component_AlertBlock, {
                  message: this.errorInstructor.name
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_14, [
          _cache[7] || (_cache[7] = _createElementVNode("label", {
            for: "choose_school",
            class: "form-group-label"
          }, "Choose school admin ", -1)),
          _createVNode(_component_Select, {
            data: _ctx.dataAdmins,
            "select-name": this.adminPlacholder,
            class: "category__select",
            onOptionSelected: _ctx.handleOptionSelected,
            title: 'full_name',
            clearActive: false
          }, null, 8, ["data", "select-name", "onOptionSelected"])
        ]),
        _cache[10] || (_cache[10] = _createElementVNode("div", { class: "create-instrctor__code-copy-title" }, " Generate school code ", -1)),
        (this.generateCode?.code == null)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "create-instrctor__button create-instrctor__button-generate",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.generateInviteCode && _ctx.generateInviteCode(...args)))
            }, " Generate "))
          : _createCommentVNode("", true),
        (this.generateCode?.code != null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, _toDisplayString(this.generateCode?.code), 1),
              _createElementVNode("div", {
                class: "create-instrctor__copy-button",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.copyText && _ctx.copyText(...args)))
              }, [
                _createVNode(_component_CopyInstructor)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.copied)
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, " Code copied! "))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_18, [
          _cache[8] || (_cache[8] = _createElementVNode("label", {
            for: "choose_school",
            class: "form-group-label"
          }, "Free trial duration ", -1)),
          _createVNode(_component_Select, {
            data: _ctx.dataTrial,
            "select-name": this.trialPlacheholder,
            class: "category__select",
            onOptionSelected: _ctx.handleOptionSelectedTrial,
            title: 'title',
            clearActive: false
          }, null, 8, ["data", "select-name", "onOptionSelected"]),
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "create-instrctor__not-necessarily" }, " Not necessarily ", -1))
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("button", _hoisted_20, [
            (!this.editTeg)
              ? (_openBlock(), _createElementBlock("span", _hoisted_21, "Create"))
              : _createCommentVNode("", true),
            (this.editTeg)
              ? (_openBlock(), _createElementBlock("span", _hoisted_22, "Save"))
              : _createCommentVNode("", true)
          ]),
          (this.serverError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _createVNode(_component_AlertBlock, {
                  message: this.serverError
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["onSubmit"])
  ]))
}